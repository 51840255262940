@import "../../styles/globals.scss";

.contact {
  margin-top: to-rem(60px);
  padding-bottom: to-rem(100px);

  @include breakpoint(tabletS) {
    padding-bottom: to-rem(140px);
  }

  @include breakpoint(tabletL) {
    padding-bottom: to-rem(200px);
  }

  &__heading {
    @include heading-3-mobile;
    transition: 1s ease;
    @include animation;
    color: white;

    @include breakpoint(tabletS) {
      @include heading-4;
    }

    @include breakpoint(laptop) {
      @include heading-5-6;
    }

    @include breakpoint(desktopL) {
      @include heading-6;
    }

    &--black {
      color: black;
    }

    &--animate {
      animation: 1.5s ease-in-out aboutSlideInLeft forwards;
    }
  }

  &__heading-underline {
    margin-top: to-rem(-5px);
    height: to-rem(1px);
    width: 0;
    transition: 1s ease;
    background-color: white;

    @include breakpoint(tabletS) {
      margin-top: to-rem(10px);
    }

    &--black {
      background-color: black;
    }

    &--animate {
      animation: 1.5s ease-in-out 1.5s underlineAnimate forwards;
    }
  }

  &__message {
    @include heading-5-6-mobile;
    margin-top: to-rem(50px);
    text-align: center;
    line-height: 1.2;

    @include breakpoint(mobileM) {
      @include heading-5-6-mobile;
      line-height: 1.2;
    }

    @include breakpoint(tabletS) {
      @include heading-7;
      margin-top: to-rem(90px);
    }

    @include breakpoint(laptop) {
      @include heading-8;
    }

    @include breakpoint(tabletL) {
      @include heading-8;
    }

    @include breakpoint(desktopS) {
      @include heading-9;
    }
  }

  &__first {
    margin-bottom: to-rem(20px);
    color: #d17b88;
    @include animation;

    @include breakpoint(desktopS) {
      @include heading-8;
    }

    &--animate {
      animation: 1.5s ease-in-out contactAnimateUp forwards;
    }
  }

  &__self-icon {
    display: block;
    width: to-rem(50px);
    margin: to-rem(20px) auto to-rem(15px) auto;
    @include animation;

    @include breakpoint(mobileM) {
      width: to-rem(70px);
    }

    @include breakpoint(tabletS) {
      width: to-rem(90px);
      margin: to-rem(50px) auto to-rem(25px) auto;
    }

    &--animate {
      animation: 1.5s ease-in-out contactAnimateUp forwards;
    }
  }

  &__second {
    margin-bottom: to-rem(30px);
    @include animation;

    @include breakpoint(tabletS) {
      margin-bottom: to-rem(50px);
    }

    @include breakpoint(desktopS) {
      @include heading-10;
    }

    &--animate {
      animation: 1.5s ease-in-out contactAnimateUp forwards;
    }
  }

  &__email {
    font-family: "Serif Italics";
    color: #00a6a6;
    width: 100%;
    @include animation;

    &--animate {
      animation: 1.5s ease-in-out contactAnimateUp forwards;
    }
  }

  &__third {
    font-family: "Serif Italics";
    @include animation;
  }

  &__social-container {
    margin-top: to-rem(40px);
    text-align: center;

    @include breakpoint(tabletS) {
      margin-top: to-rem(60px);
    }
  }

  &__anchor {
    margin-right: to-rem(30px);
    display: inline-block;
    @include animation;
    transition: 0.5s ease-in-out;

    &:hover {
      transform: translateY(-10px);
    }

    &:last-child {
      margin-right: 0;
    }

    &--animate0 {
      animation: 1s ease-in-out contactAnimateUp forwards;
    }
    &--animate1 {
      animation: 1s ease-in-out 0.4s contactAnimateUp forwards;
    }
    &--animate2 {
      animation: 1s ease-in-out 0.8s contactAnimateUp forwards;
    }
  }

  &__icons {
    height: to-rem(28px);
    width: to-rem(28px);

    @include breakpoint(mobileM) {
      height: to-rem(32px);
      width: to-rem(32px);
    }

    @include breakpoint(tabletS) {
      height: to-rem(40px);
      width: to-rem(40px);
    }

    @include breakpoint(desktopL) {
      height: to-rem(45px);
      width: to-rem(45px);
    }
  }
}

@keyframes contactAnimateUp {
  from {
    top: 100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
