@import "../../styles/globals.scss";

.skills {
  width: 100%;
  padding-bottom: to-rem(20px);

  @include breakpoint(desktopS) {
    padding-bottom: to-rem(30px);
  }

  &__heading {
    // position: relative;
    @include heading-3-mobile;
    // opacity: 0;
    color: white;
    transition: 1s ease;

    @include breakpoint(tabletS) {
      @include heading-4;
    }

    @include breakpoint(laptop) {
      @include heading-5-6;
    }

    @include breakpoint(desktopL) {
      @include heading-6;
    }

    &--animate {
      animation: 1.5s ease-in-out aboutSlideInLeft forwards;
    }
  }

  &__underline {
    margin-top: to-rem(-5px);
    height: to-rem(1px);
    // width: 0%;
    width: 100%;
    background-color: white;
    position: relative;

    @include breakpoint(tabletS) {
      margin-top: to-rem(10px);
    }

    &--animate {
      animation: 1.5s ease-in-out 1.5s underlineAnimate forwards;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: to-rem(40px);

    @include breakpoint(tabletS) {
      margin-top: to-rem(50px);
    }

    @include breakpoint(laptop) {
      max-width: to-rem(768px);
      margin: to-rem(50px) auto 0 auto;
    }

    @include breakpoint(tabletL) {
      margin-top: to-rem(70px);
    }

    @include breakpoint(desktopS) {
      max-width: to-rem(960px);
      margin-top: to-rem(60px);
    }
  }

  &__left-container {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    transition: margin 1000ms;
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint(tabletS) {
      width: to-rem(640px);
      height: to-rem(768px);
    }

    @include breakpoint(laptop) {
      width: to-rem(500px);
      height: to-rem(628px);
    }

    @include breakpoint(desktopS) {
      width: to-rem(640px);
      height: to-rem(768px);
    }

    &--animate {
      animation: 1s containerLeft forwards ease-in-out;
    }
  }

  &__image {
    position: absolute;
    height: auto;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    @include breakpoint(laptop) {
      pointer-events: all;
    }

    &--mobile-animate {
      animation: 1s iconsUp forwards ease-in-out;
    }

    &--react {
      width: 47%;
      left: 0;
      top: 13%;

      @include breakpoint(tabletS) {
        width: 32.5%;
        top: 20.9%;
        left: 6.9%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleReact forwards ease-in-out;
      }
    }

    &--HTML {
      width: 40%;
      left: 29%;
      top: 0;

      @include breakpoint(tabletS) {
        width: 27.4%;
        top: 0;
        left: 25.8%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleHtml forwards 0.2s ease-in-out;
      }
    }

    &--sass {
      width: 43%;
      left: 57%;
      top: 11%;

      @include breakpoint(tabletS) {
        width: 29.7%;
        top: 6.3%;
        left: 53.1%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleSass forwards 0.4s ease-in-out;
      }
    }

    &--git {
      width: 28%;
      top: 47%;
      left: 71%;

      @include breakpoint(tabletS) {
        width: 19.4%;
        top: 27.9%;
        left: 75.5%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleGit forwards 0.6s ease-in-out;
      }
    }

    &--native {
      width: 49%;
      top: 58%;
      left: 41%;

      @include breakpoint(tabletS) {
        width: 33.7%;
        top: 51.3%;
        left: 66.3%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleReactNative forwards 0.8s ease-in-out;
      }
    }

    &--express {
      width: 38%;
      top: 74%;
      left: 17%;

      @include breakpoint(tabletS) {
        width: 26%;
        top: 74.1%;
        left: 50.3%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleExpress forwards 1s ease-in-out;
      }
    }

    &--node {
      width: 46%;
      top: 49%;
      left: 4%;

      @include breakpoint(tabletS) {
        width: 31.4%;
        top: 64.5%;
        left: 18.6%;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleNode forwards 1.2s ease-in-out;
      }
    }

    &--firebase {
      width: 36%;
      top: 80%;
      left: 64%;

      @include breakpoint(tabletS) {
        width: 24.6%;
        top: 51.8%;
        left: 0;
        opacity: 0;
      }

      &-animate {
        animation: 0.2s circleFirebase forwards 1.4s ease-in-out;
      }
    }

    &--javaScript {
      width: 58%;
      top: 27%;
      left: 25%;

      @include breakpoint(tabletS) {
        width: 40%;
        top: 32.2%;
        left: 33%;
      }

      &-animate {
        animation: 1s iconsUp forwards ease-in-out;
      }
    }

    &--grayscale {
      filter: grayscale(100%);
    }
  }

  &__right-container {
    display: none;
    color: white;

    @include breakpoint(laptop) {
      margin-left: auto;
      top: 0;
      position: relative;
    }

    &--show {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: to-rem(90px);
    }

    &--animate {
      animation: 1s containerRight forwards ease-in-out;
    }

    &--animate-delay {
      opacity: 0;
      animation: 1s containerRight 0.5s forwards ease-in-out;
    }
  }

  &__tech {
    @include breakpoint(laptop) {
      @include heading-7;
      line-height: 1;
      font-family: "Serif Italics";
      text-transform: capitalize;
    }
    @include breakpoint(desktopS) {
      @include heading-7;
      line-height: 1;
      margin-bottom: to-rem(20px);
      font-family: "Serif Italics";
      text-transform: capitalize;
    }

    &--react {
      color: $react;
    }

    &--sass {
      color: $sass;
    }

    &--HTML {
      color: $html;
    }

    &--git {
      color: $git;
    }

    &--native {
      color: $reactnative;
    }

    &--node {
      color: $node;
    }

    &--firebase {
      color: $firebase;
    }

    &--express {
      color: #fff;
    }

    &--javaScript {
      color: $javascript;
    }
  }

  &__used {
    @include breakpoint(laptop) {
      @include body-large;
      color: $darkgrey;
      line-height: 2;
    }

    @include breakpoint(desktopS) {
      @include body-large;
      line-height: 2;
      color: $darkgrey;
    }
  }

  &__projects {
    @include breakpoint(laptop) {
      @include body-large;
      line-height: 1.6;
    }
    @include breakpoint(desktopS) {
      @include body-large;
      line-height: 1.6;
    }
  }
}

@keyframes atom {
  from {
    transform: rotate(0deg) translateX(250px) rotate(0deg);
  }
  to {
    transform: rotate(360deg) translateX(250px) rotate(-360deg);
  }
}

@keyframes atomHTML {
  from {
    transform: rotate(45deg) translateX(250px) rotate(-45deg);
  }
  to {
    transform: rotate(405deg) translateX(250px) rotate(-405deg);
  }
}
@keyframes atomSass {
  from {
    transform: rotate(90deg) translateX(250px) rotate(-90deg);
  }
  to {
    transform: rotate(450deg) translateX(250px) rotate(-450deg);
  }
}
@keyframes atomGit {
  from {
    transform: rotate(135deg) translateX(250px) rotate(-135deg);
  }
  to {
    transform: rotate(495deg) translateX(250px) rotate(-495deg);
  }
}
@keyframes atomReactNative {
  from {
    transform: rotate(180deg) translateX(250px) rotate(-180deg);
  }
  to {
    transform: rotate(540deg) translateX(250px) rotate(-540deg);
  }
}
@keyframes atomExpress {
  from {
    transform: rotate(225deg) translateX(250px) rotate(-225deg);
  }
  to {
    transform: rotate(585deg) translateX(250px) rotate(-585deg);
  }
}
@keyframes atomNode {
  from {
    transform: rotate(270deg) translateX(250px) rotate(-270deg);
  }
  to {
    transform: rotate(630deg) translateX(250px) rotate(-630deg);
  }
}
@keyframes atomFirebase {
  from {
    transform: rotate(315deg) translateX(250px) rotate(-315deg);
  }
  to {
    transform: rotate(675deg) translateX(250px) rotate(-675deg);
  }
}

@keyframes circleReact {
  from {
    top: 32.2%;
    left: 33%;
    opacity: 0;
  }
  to {
    top: 20.9%;
    left: 6.9%;
    opacity: 1;
  }
}

@keyframes circleHtml {
  from {
    top: 20.9%;
    left: 6.9%;
    opacity: 0;
  }
  to {
    top: 0;
    left: 25.8%;
    opacity: 1;
  }
}

@keyframes circleSass {
  from {
    top: 0;
    left: 25.8%;
    opacity: 0;
  }
  to {
    top: 6.3%;
    left: 53.1%;
    opacity: 1;
  }
}

@keyframes circleGit {
  from {
    top: 6.3%;
    left: 53.1%;
    opacity: 0;
  }
  to {
    top: 27.9%;
    left: 75.5%;
    opacity: 1;
  }
}

@keyframes circleReactNative {
  from {
    top: 27.9%;
    left: 75.5%;
    opacity: 0;
  }
  to {
    top: 51.3%;
    left: 66.3%;
    opacity: 1;
  }
}

@keyframes circleExpress {
  from {
    top: 51.3%;
    left: 66.3%;
    opacity: 0;
  }
  to {
    top: 74.1%;
    left: 50.3%;
    opacity: 1;
  }
}

@keyframes circleNode {
  from {
    top: 74.1%;
    left: 50.3%;
    opacity: 0;
  }
  to {
    top: 64.5%;
    left: 18.6%;
    opacity: 1;
  }
}

@keyframes circleFirebase {
  from {
    top: 64.5%;
    left: 18.6%;
    opacity: 0;
  }
  to {
    top: 51.8%;
    left: 0;
    opacity: 1;
  }
}

@keyframes containerLeft {
  from {
    left: 50%;
    transform: translateX(-50%);
  }
  to {
    left: 0;
    transform: translateX(0);
  }
}

@keyframes containerRight {
  0% {
    opacity: 0;
    top: to-rem(100px);
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes iconsUp {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
