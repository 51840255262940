// Breakpoints
$breakpoint-mobileM-width-min: 375px;
$breakpoint-tabletS-width-min: 768px;
$breakpoint-tabletL-laptop-width-min: 1024px;
$breakpoint-desktopS-width-min: 1280px;
$breakpoint-desktopL-width-min: 1440px;

$breakpoint-tabletL-height-min: 1000px;

//colours
$white: #fff;
$background-black: rgb(13, 13, 13);
$lightgrey: #c0c0c0;
$darkgrey: #6d686d;
$mountadex-skyblue: rgb(220, 227, 235);
$mountadex-lightblue: rgb(185, 204, 226);
$mountadex-paletteblue: rgb(81, 107, 137);
$mountadex-hoverblue: rgb(25, 39, 54);
$mountadex-navyblue: rgb(8, 14, 24);
$mountadex-bluemountain: rgb(123, 187, 246);
$mountadex-paletteblue-opacity: rgba(81, 107, 137, 0.5);

$omdb-pink: #ffc0cb;
$eventfriends-blue: rgb(184, 226, 255);
$check-blue: rgb(135, 178, 232);
$dog-red: rgb(245, 238, 188);
$brainflix: rgb(221, 217, 250);
$dragcity: rgb(252, 215, 215);

$react: rgb(14, 220, 254);
$sass: rgb(219, 95, 154);
$html: rgb(245, 61, 1);
$git: rgb(254, 63, 29);
$reactnative: rgb(0, 218, 254);
$node: rgb(94, 173, 88);
$firebase: rgb(254, 202, 0);
$javascript: rgb(243, 220, 25);
