// Mobile typography

@mixin heading-1-mobile {
  font-size: 5rem;
  line-height: 1;
}

@mixin heading-2-mobile {
  font-size: 4rem;
  line-height: 1;
}

@mixin heading-3-mobile {
  font-size: 3rem;
  line-height: 1;
}

@mixin heading-4-mobile {
  font-size: 2.5rem;
}

@mixin heading-5-mobile {
  font-size: 2rem;
}

@mixin heading-5-6-mobile {
  font-size: 1.7rem;
}

@mixin heading-6-mobile {
  font-size: 1.5rem;
}

@mixin body-large-mobile {
  font-size: 1.8rem;
}

@mixin body-regular-mobile {
  font-size: 1.6rem;
}

@mixin body-medium-mobile {
  font-size: 1.2rem;
}

@mixin body-small-mobile {
  font-size: 0.9rem;
  line-height: 1.5;
}

@mixin view-width-mobile {
  font-size: 11.55vw;
}

//Desktop typography
@mixin heading-1 {
  font-size: 15.625vw;
}

@mixin heading-2 {
  font-size: 12.625vw;
}

@mixin heading-3 {
  font-size: 11.125vw;
}

@mixin heading-4 {
  font-size: 9.75vw;
}

@mixin heading-5 {
  font-size: 7.8125vw;
  line-height: 10.41667vw;
}

@mixin heading-5-6 {
  font-size: 6.5317vw;
}
@mixin heading-6 {
  font-size: 5.4317vw;
}
@mixin heading-7 {
  font-size: 4.7317vw;
}

@mixin heading-8 {
  font-size: 3.7317vw;
}

@mixin heading-9 {
  font-size: 3vw;
}

@mixin heading-10 {
  font-size: 2.8vw;
}

@mixin body-large {
  font-size: 2.125vw;
  line-height: 3vw;
}

@mixin body-medium {
  font-size: 1.525vw;
  line-height: 2.5vw;
}

@mixin body-regular {
  font-size: 1rem;
  line-height: 26.66px;
}

@mixin body-small {
  font-size: 0.83333vw;
  line-height: 1.66667vw;
}

// General typography

@mixin link {
  text-decoration: none;
  cursor: pointer;
}
