@import "../../styles/globals.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: to-rem(70px);
  background-color: transparent;
  transition: 1s ease;
  padding: 0 to-rem(32px);
  z-index: 100;

  @include breakpoint(tabletS) {
    padding: 0 to-rem(64px);
    height: to-rem(75px);
  }

  @include breakpoint(tabletL) {
    position: fixed;
  }

  @include breakpoint(laptop) {
    padding: 0 to-rem(120px);
    height: to-rem(70px);
    position: absolute;
  }
  @include breakpoint(tabletL) {
    height: to-rem(75px);
  }

  @include breakpoint(desktopS) {
    padding: 0 to-rem(160px);
    padding-top: to-rem(10px);
  }

  @include breakpoint(desktopL) {
    padding: 0 to-rem(200px);
  }

  &--black {
    background-color: $background-black;
  }

  &--top {
    transform: translateY(-70px);
  }

  &--expand {
    z-index: 100;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $background-black;
    overflow: hidden;
    position: fixed;

    @include breakpoint(tabletL) {
      position: fixed;
    }

    @include breakpoint(laptop) {
      position: absolute;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &__logo {
    fill: #fff;
    cursor: pointer;
    width: to-rem(28px);
    height: to-rem(28px);
    @include animation;
    top: to-rem(18px);

    &--animate {
      animation: 1.5s ease-out 1s logoInLeft forwards;
    }

    @include breakpoint(tabletS) {
      width: to-rem(40px);
      height: to-rem(40px);
    }

    @include breakpoint(laptop) {
      width: to-rem(35px);
      height: to-rem(35px);
    }

    @include breakpoint(tabletL) {
      width: to-rem(40px);
      height: to-rem(40px);
    }

    @include breakpoint(desktopS) {
      top: to-rem(8px);
    }

    @include breakpoint(desktopL) {
      top: to-rem(18px);
    }
  }

  &__menu {
    width: to-rem(20px);
    @include animation;

    top: to-rem(28px);
    color: black;
    height: 15px;
    padding: 0;
    border: none;
    background: none;

    &--animate {
      animation: 1.5s ease-out 1s fadeIn forwards;
    }

    @include breakpoint(tabletL) {
      top: to-rem(30px);
    }

    @include breakpoint(desktopS) {
      top: to-rem(18px);
    }

    @include breakpoint(desktopL) {
      top: to-rem(28px);
    }
  }

  &__nav {
    margin: 0 auto;
    color: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    @include heading-4-mobile;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(tabletS) {
      @include heading-2-mobile;
    }

    @include breakpoint(laptop) {
      @include heading-2-mobile;
      top: 17%;
    }

    @include breakpoint(desktopS) {
      top: 20%;
    }

    &--hide {
      display: none;
    }
  }

  &__link {
    @include link;
    @include heading-4-mobile;
    @include animation;
    cursor: pointer;
    text-align: center;
    margin-bottom: to-rem(20px);
    color: white;
    padding: 0;
    border: none;
    background: none;
    transition: color 500ms linear;

    @include breakpoint(tabletS) {
      margin-bottom: to-rem(30px);
      @include heading-2-mobile;
    }

    @include breakpoint(laptop) {
      @include heading-2-mobile;
      margin-bottom: to-rem(15px);
    }

    @include breakpoint(desktopS) {
      margin-bottom: to-rem(15px);
    }

    &:hover {
      // text-decoration: underline;
      color: #d17b88;
    }

    &--0 {
      animation: 0.4s ease-in linksInOne forwards;
    }
    &--1 {
      animation: 0.8s ease-in linksInTwo forwards;
    }
    &--2 {
      animation: 1.2s ease-in linksInThree forwards;
    }
    &--3 {
      animation: 1.6s ease-in linksInFour forwards;
    }
    &--4 {
      animation: 2s ease-in linksInFive forwards;
    }
  }

  &__bottom-container {
    margin-top: to-rem(40px);
    text-align: center;

    @include breakpoint(tabletS) {
      margin-top: to-rem(80px);
    }
  }

  &__social-container {
    display: flex;
    justify-content: center;
  }

  &__anchor {
    display: inline-block;
    margin: 0 to-rem(15px);
    transition: 0.5s ease-in-out;

    @include breakpoint(tabletS) {
      margin: 0 to-rem(20px);
    }

    &:hover {
      transform: translateY(-10px);
    }
  }

  &__icons {
    width: to-rem(25px);
    fill: white;

    @include breakpoint(tabletS) {
      width: to-rem(35px);
    }
  }

  &__email {
    @include body-medium-mobile;
    font-family: "Serif Italics";
    transition: color 500ms linear;

    @include breakpoint(tabletS) {
      @include heading-5-mobile;
    }

    &:hover {
      color: #00a6a6;
    }
  }
}

#header__span--light {
  stroke: white;
  fill: white;
  color: white;
  & > div svg {
    stroke: white;
  }
  & > div > div span:before {
    background: white;
  }
  & > div > div span:after {
    background: white;
  }
}

input {
  display: none;
  &:checked + label > div {
    div {
      transform: rotate(90deg);
      span {
        &:before,
        &:after {
          background: black;
        }
        &:first-child {
          &:before {
            transform: rotate(45deg) translate(2.2px, -3px) scaleX(1.05);
          }
          &:after {
            transform: rotate(-45deg) translate(-2.2px, -3px) scaleX(1.05);
          }
        }
        &:last-child {
          &:before {
            transform: rotate(-45deg) translate(2.2px, 3px) scaleX(1.05);
          }
          &:after {
            transform: rotate(45deg) translate(-2.2px, 3px) scaleX(1.05);
          }
        }
      }
    }
    svg {
      stroke-dashoffset: 62;
      stroke-dasharray: 0 82.801 62 82.801;
      transform: rotate(90deg);
      stroke: black;
      &:nth-child(3) {
        transform: rotate(270deg);
      }
    }
  }
}

.toggle {
  display: block;
  cursor: pointer;
  & > div {
    width: 20px;
    height: 14px;
    position: relative;
    div {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: transform 0.5s ease;
      span {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        &:first-child {
          top: 0;
          &:before,
          &:after {
            top: 0;
          }
        }
        &:last-child {
          bottom: 0;
          &:before,
          &:after {
            bottom: 0;
          }
        }
        &:before,
        &:after {
          content: "";
          display: block;
          width: 47%;
          height: to-rem(2px);
          border-radius: 1px;
          background: black;
          position: absolute;
          -webkit-backface-visibility: hidden;
          transition: transform 0.5s ease, border-radius 0.3s ease,
            background 0.4s ease;
        }
        &:before {
          left: 0;
          transform-origin: 0 50%;
          transform: translate(1px, 0) scaleX(1.1);
        }
        &:after {
          right: 0;
          transform-origin: 100% 50%;
          transform: translate(-1px, 0) scaleX(1.1);
        }
      }
    }
    svg {
      display: block;
      fill: none;
      stroke: black;
      stroke-width: to-rem(2px);
      width: to-rem(44px);
      height: to-rem(44px);
      stroke-linecap: round;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: to-rem(-22px) 0 0 to-rem(-22px);
      stroke-dasharray: 0 82.801 8 82.801;
      stroke-dashoffset: 82.801;
      transform-origin: 50% 50%;
      -webkit-backface-visibility: hidden;
      transform: scale(1);
      transition: stroke-dashoffset 0.5s ease, stroke-dasharray 0.6s ease,
        transform 0.5s ease, stroke 0.4s ease;
      &:nth-child(3) {
        transform: rotate(180deg) scale(1);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    top: 128px;
  }
  100% {
    opacity: 1;
    top: 28px;
  }
}

@keyframes logoInLeft {
  0% {
    left: 100px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes linksInOne {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes linksInTwo {
  0% {
    left: 100px;
    opacity: 0%;
  }
  20% {
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes linksInThree {
  0% {
    left: -100px;
    opacity: 0;
  }
  40% {
    opacity: 0%;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes linksInFour {
  0% {
    left: 100px;
    opacity: 0;
  }
  60% {
    opacity: 0%;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

@keyframes linksInFive {
  0% {
    left: -100px;
    opacity: 0;
  }
  80% {
    opacity: 0%;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
