@import "./fonts.scss";
@import "./reset.scss";
@import "./variables.scss";
@import "./fonts.scss";
@import "./globals.scss";

body {
  font-family: "Serif Regular", sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.body {
  overflow: hidden;

  &--fixed {
    position: fixed;
  }
}

.body-header {
  overflow: hidden;
}

a {
  color: inherit;
}

button {
  font-family: inherit;
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skip-button {
  font-family: "Serif Regular";
  border-radius: to-rem(10px);
  position: fixed;
  bottom: to-rem(20px);
  right: to-rem(20px);
  z-index: 20000;
  background: none;
  border: none;
  background-color: $background-black;
  border-radius: 50%;
  padding: to-rem(15px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 1);
  cursor: pointer;
  opacity: 0;

  @include breakpoint(tabletS) {
    padding: to-rem(20px);
    bottom: to-rem(40px);
    right: to-rem(40px);
  }

  @include breakpoint(tabletL) {
    padding: to-rem(20px);
    bottom: to-rem(40px);
    right: to-rem(40px);
    position: fixed;
  }

  @include breakpoint(laptop) {
    padding: to-rem(18px);
    bottom: to-rem(40px);
    right: to-rem(40px);
    position: absolute;
  }

  @include breakpoint(desktopL) {
    padding: to-rem(20px);
    bottom: 7%;
    right: 5%;
  }

  &:hover {
    svg {
      animation: 0.8s ease-in-out arrowDown infinite;
    }
  }

  &--hide {
    display: none;
  }

  &--animate {
    animation: 1s ease-in-out arrowAnimate forwards;
  }
}

.skip-arrow {
  width: to-rem(20px);
  fill: white;
  transition: 1s ease-in-out;

  @include breakpoint(tabletS) {
    width: to-rem(25px);
  }

  @include breakpoint(tabletL) {
    width: to-rem(25px);
  }

  @include breakpoint(laptop) {
    width: to-rem(23px);
  }

  @include breakpoint(desktopL) {
    width: to-rem(25px);
  }
}

@keyframes arrowDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
}

@keyframes arrowAnimate {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
