@import "../../styles/globals.scss";

.landing {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;

  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fff;

  &--animate {
    animation: 4s forwards fadeOut;
  }

  &__name-container {
    padding: 0 to-rem(32px);
    margin-top: to-rem(90px);
    display: flex;
    @include heading-3-mobile;
    width: 100%;
    z-index: 2;
    justify-content: center;

    @include breakpoint(mobileM) {
      @include heading-2-mobile;
    }

    @include breakpoint(tabletS) {
      margin-top: to-rem(150px);
      @include heading-2;
    }

    @include breakpoint(laptop) {
      margin-top: to-rem(120px);
      @include heading-5;
    }

    @include breakpoint(tabletL) {
      margin-top: to-rem(180px);
    }

    @include breakpoint(desktopS) {
      margin-top: to-rem(90px);
    }

    @include breakpoint(desktopL) {
      @include heading-5-6;
    }
  }

  &__first {
    position: relative;
    animation: 3s ease-in-out slideInLeft;

    @include breakpoint(tabletS) {
      animation: 3s ease-in-out slideInLeftTablet;
    }
  }

  &__last {
    position: relative;
    animation: 3s ease-in-out slideInRight;
    margin-left: to-rem(5px);
    top: to-rem(30px);

    @include breakpoint(tabletS) {
      top: to-rem(50px);
      animation: 3s ease-in-out slideInRightTablet;
    }
  }
}

@keyframes slideInLeft {
  0% {
    right: 20%;
    top: to-rem(15px);
  }

  30% {
    right: 0%;
    top: to-rem(15px);
  }
  80% {
    right: 0%;
    top: to-rem(15px);
  }
  100% {
    top: 0;
  }
}

@keyframes slideInLeftTablet {
  0% {
    right: 20%;
    top: to-rem(25px);
  }

  30% {
    right: 0%;
    top: to-rem(25px);
  }
  80% {
    right: 0%;
    top: to-rem(25px);
  }
  100% {
    top: 0;
  }
}

@keyframes slideInRight {
  0% {
    left: 20%;
    top: to-rem(15px);
  }
  30% {
    left: 0%;
    top: to-rem(15px);
  }
  80% {
    left: 0%;
    top: to-rem(15px);
  }
  100% {
    top: to-rem(30px);
  }
}

@keyframes slideInRightTablet {
  0% {
    left: 20%;
    top: to-rem(25px);
  }
  30% {
    left: 0%;
    top: to-rem(25px);
  }
  80% {
    left: 0%;
    top: to-rem(25px);
  }
  100% {
    top: to-rem(50px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    z-index: 1;
    color: rgb(13, 13, 13);
    background-color: #fff;
  }
  90% {
    opacity: 0;
    z-index: 1;
  }
  100% {
    color: #c0c0c0;
    z-index: -1;
    opacity: 0;
    background-color: #000;
  }
}
