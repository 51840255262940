@font-face {
  font-family: "Serif Regular";
  src: url("../assets/fonts/serif-beta/serif-regular.otf") format("opentype");
}

@font-face {
  font-family: "Serif Bold";
  src: url("../assets/fonts/serif-beta/serif-bold.otf") format("opentype");
}

@font-face {
  font-family: "Serif Black";
  src: url("../assets/fonts/serif-beta/serif-black.otf") format("opentype");
}

@font-face {
  font-family: "Serif Italics";
  src: url("../assets/fonts/serif-beta/serif-italics.otf") format("opentype");
}
