@import "../../styles/globals.scss";

.projects {
  width: 100%;
  padding: to-rem(60px) 0 to-rem(80px) 0;

  @include breakpoint(tabletS) {
    padding: to-rem(200px) 0 to-rem(80px) 0;
  }

  @include breakpoint(laptop) {
    padding: to-rem(200px) 0 to-rem(80px) 0;
  }

  &__heading {
    position: relative;
    @include heading-3-mobile;
    opacity: 0;
    color: black;
    transition: 1s ease;

    @include breakpoint(tabletS) {
      @include heading-4;
    }

    @include breakpoint(laptop) {
      @include heading-5-6;
    }

    @include breakpoint(desktopL) {
      @include heading-6;
    }

    &--animate {
      animation: 1.5s ease-in-out aboutSlideInLeft forwards;
    }
  }

  &__underline {
    margin-top: to-rem(-9px);
    height: to-rem(1px);
    width: 0%;
    background-color: black;
    position: relative;

    @include breakpoint(tabletS) {
      margin-top: to-rem(10px);
    }

    &--animate {
      animation: 1.5s ease-in-out 1.5s underlineAnimate forwards;
    }
  }
}

@keyframes underlineAnimate {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes aboutSlideInLeft {
  0% {
    opacity: 0;
    left: -40px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
