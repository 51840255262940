@import "../../styles/globals.scss";

.hero {
  padding: 0 0 to-rem(80px) 0;

  @include breakpoint(mobileM) {
    padding: 0 0 to-rem(140px) 0;
  }

  @include breakpoint(tabletL) {
    padding: 0 0 to-rem(0px) 0;
  }

  &__name-container {
    padding-top: to-rem(90px);
    line-height: 0.7;
    text-align: center;

    @include breakpoint(tabletS) {
      padding-top: to-rem(150px);
    }

    @include breakpoint(laptop) {
      padding-top: to-rem(120px);
    }
    @include breakpoint(tabletL) {
      padding-top: to-rem(180px);
    }

    @include breakpoint(desktopS) {
      padding-top: to-rem(90px);
    }
  }

  &__heading {
    color: $lightgrey;
    @include heading-3-mobile;

    @include breakpoint(mobileM) {
      @include heading-2-mobile;
    }

    @include breakpoint(tabletS) {
      @include heading-2;
    }

    @include breakpoint(laptop) {
      @include heading-5;
    }
    @include breakpoint(desktopL) {
      @include heading-5-6;
    }
  }

  &__first-name {
    position: relative;
  }

  &__last-name {
    margin-left: to-rem(5px);
    position: relative;
    top: to-rem(30px);

    @include breakpoint(tabletS) {
      top: to-rem(50px);
    }
  }

  &__fullstack {
    @include body-large-mobile;
    margin-top: to-rem(60px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__projects {
    display: flex;
    margin: 40px to-rem(-32px) 5px to-rem(-32px);
    height: auto;

    @include breakpoint(tabletS) {
      margin: 60px to-rem(-64px) 5px to-rem(-64px);
    }

    @include breakpoint(laptop) {
      justify-content: center;
      margin: 40px to-rem(-64px) 5px to-rem(-64px);
    }

    @include breakpoint(desktopS) {
      margin-top: 10px;
      z-index: -1;
    }
  }

  &__project-containers {
    width: 100%;
    @include breakpoint(laptop) {
      width: 55%;
    }
    @include breakpoint(desktopL) {
      width: 50%;
    }

    &--hide {
      display: none;
    }

    &--checkitoff {
    }

    &--mountadex {
      position: relative;
    }

    &--dawg {
      position: relative;
    }

    &--brainflix {
      position: relative;
    }

    &--event {
      position: relative;
    }
  }

  &__omdb-image {
    width: 100%;
    transition: 2s ease-in-out;
    opacity: 0;

    &--laptop {
      position: relative;

      &--animation {
        animation: 1s ease-in-out forwards translateDownOne;
      }
    }

    &--tablet {
      position: absolute;
      top: 0;
      left: 0;

      &--animation {
        animation: 1s ease-in-out 0.5s forwards translateDownTwo;
      }
    }

    &--phone {
      position: absolute;
      top: 0;
      left: 0;

      &--animation {
        animation: 1s ease-in-out 0.3s forwards translateDownThree;
      }
    }
  }

  &__mountadex-image {
    width: 100%;
    opacity: 0;

    &--laptop-initial {
      position: relative;
    }

    &--laptop {
      animation: 1s ease-in-out forwards translateDownOne;
    }

    &--phone-initial {
      position: absolute;
      top: 0;
      left: 0;
    }

    &--phone {
      animation: 1s ease-in-out 0.5s forwards translateDownThree;
    }
  }

  &--checkitoff {
    width: 100%;
    position: relative;
    animation: 1s ease-in-out translateDownThree forwards;

    margin-right: to-rem(-32px);
  }

  &__image {
    object-fit: contain;
    opacity: 0;

    @include breakpoint(tabletL) {
      width: 100%;
    }

    &--position {
      position: relative;
    }

    &--initial {
      animation: 2s ease-out 1s forwards translateDown;
    }
    &--initial-one {
      animation: 1.5s ease-out 1.5s forwards translateDown;
    }
    &--initial-two {
      animation: 1s ease-out 2s forwards translateDown;
    }
  }

  &__dawg-image {
    width: 100%;
    opacity: 0;

    &--laptop {
      position: relative;
      animation: 1s ease-in-out forwards translateDownOne;
    }

    &--tablet {
      position: absolute;
      top: 0;
      left: 0;
      animation: 1s ease-in-out 0.3s forwards translateDownTwo;
    }

    &--phone {
      position: absolute;
      top: 0;
      left: 0;
      animation: 1s ease-in-out 0.5s forwards translateDownThree;
    }
  }

  &__brainflix-image {
    width: 100%;
    transition: 2s ease-in-out;
    opacity: 0;

    &--laptop {
      position: relative;
      animation: 1s ease-in-out forwards translateDownOne;
    }

    &--tablet {
      position: absolute;
      top: 0;
      left: 0;
      animation: 1s ease-in-out 0.5s forwards translateDownTwo;
    }

    &--phone {
      position: absolute;
      top: 0;
      left: 0;
      animation: 1s ease-in-out 0.3s forwards translateDownThree;
    }
  }

  &__event-image {
    position: absolute;
    width: 100%;
    opacity: 0;

    &--one {
      position: relative;
      animation: 1s ease-in-out forwards translateDownOne;
    }
    &--two {
      top: 0;
      left: 0;
      animation: 1s ease-in-out 0.3s forwards translateDownTwo;
    }
  }

  &__container {
    position: relative;
    @include body-large-mobile;
    width: 100%;
    line-height: 0.9;
    text-align: end;
    display: flex;
    flex-direction: column;

    @include breakpoint(mobileM) {
      margin-top: to-rem(30px);
    }

    @include breakpoint(tabletS) {
      padding-bottom: to-rem(60px);
    }
    @include breakpoint(laptop) {
      text-align: center;
      margin: 0;
      padding-bottom: to-rem(30px);
    }
  }

  &__full {
    color: $white;
    @include animation;
    transition: 1s ease;

    &--animate {
      animation: 1s ease-out 1s translateUpOne forwards;
    }

    @include breakpoint(tabletS) {
      @include heading-6;
    }

    @include breakpoint(laptop) {
      @include body-large;
    }
  }

  &__location-container {
    display: flex;
    flex-direction: column;

    @include breakpoint(laptop) {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__sub-text {
    @include body-regular-mobile;
    line-height: 0.9;
    color: $darkgrey;
    font-family: "Serif Italics";
    @include breakpoint(tabletS) {
      @include heading-7;
    }
    @include breakpoint(laptop) {
      @include body-large;
    }
  }

  &__based {
    @include animation;

    &--animate {
      animation: 1.5s ease-out 1s translateUpTwo forwards;
    }
  }
  &__location {
    @include animation;

    @include breakpoint(laptop) {
      margin-left: to-rem(5px);
    }

    &--animate {
      animation: 2s ease-out 1s translateUpThree forwards;

      @include breakpoint(laptop) {
        animation: 1.5s ease-out 1s translateUpTwo forwards;
      }
    }
  }

  &__arrow-container {
    height: to-rem(30px);
    width: to-rem(30px);
    margin: 0 auto;
    margin-top: to-rem(40px);

    @include breakpoint(tabletS) {
      height: to-rem(40px);
      width: to-rem(40px);
      margin-top: to-rem(0px);
    }

    @include breakpoint(laptop) {
      height: to-rem(30px);
      width: to-rem(30px);
    }
  }

  &__arrow {
    width: 100%;
    fill: #fff;
    enable-background: new 0 0 512 512;
  }
}

@keyframes translateDown {
  0% {
    top: -30%;
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes translateDownOne {
  0% {
    top: -25%;
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes translateDownTwo {
  0% {
    top: -27%;
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}
@keyframes translateDownThree {
  0% {
    top: -30%;
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes translateUpOne {
  0% {
    top: 40px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes translateUpTwo {
  0% {
    top: 20px;
    opacity: 0;
  }
  33% {
    top: 20px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}

@keyframes translateUpThree {
  0% {
    top: 20px;
    opacity: 0;
  }
  66% {
    top: 20px;
    opacity: 0;
  }
  100% {
    top: 0px;
    opacity: 1;
  }
}
