@function strip-units($number) {
  @return $number / ($number * 0 + 1);
}

@function to-rem($pixelValue) {
  @return (strip-units($pixelValue) / 16) + rem;
}

@mixin breakpoint($breakpoint) {
  @if $breakpoint == mobileM {
    @media (min-width: $breakpoint-mobileM-width-min) {
      @content;
    }
  }
  @if $breakpoint == tabletS {
    @media (min-width: $breakpoint-tabletS-width-min) {
      @content;
    }
  }

  @if $breakpoint == tabletL {
    @media (min-width: $breakpoint-tabletL-laptop-width-min) and (min-height: $breakpoint-tabletL-height-min) {
      @content;
    }
  }

  @if $breakpoint == laptop {
    @media (min-width: $breakpoint-tabletL-laptop-width-min) {
      @content;
    }
  }

  @if $breakpoint == desktopS {
    @media (min-width: $breakpoint-desktopS-width-min) {
      @content;
    }
  }

  @if $breakpoint == desktopL {
    @media (min-width: $breakpoint-desktopL-width-min) {
      @content;
    }
  }
}

@mixin container {
  padding: 0 to-rem(24px);

  @include breakpoint(tabletS) {
    padding: 0 to-rem(36px);
  }

  @include breakpoint(desktopS) {
    padding: 0 to-rem(160px);
  }
}

@mixin animation {
  position: relative;
  opacity: 0;
}

@mixin link {
  text-decoration: none;
}
