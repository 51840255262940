@import "../../styles/globals.scss";

.home {
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  padding: 0 to-rem(32px);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include breakpoint(tabletS) {
    padding: 0 to-rem(64px);
  }
  @include breakpoint(laptop) {
    padding: 0 to-rem(120px);
  }
  @include breakpoint(desktopS) {
    padding: 0 to-rem(160px);
  }
  @include breakpoint(desktopL) {
    padding: 0 to-rem(200px);
  }
}

.home--background-black {
  background-color: $background-black;
}

.home--background-white {
  background-color: #fff;
}

.home--background-omdb {
  background-color: $omdb-pink;
}

.home--background-mountadex {
  background-color: $mountadex-lightblue;
}

.home--background-event {
  background-color: $eventfriends-blue;
}

.home--background-check {
  background-color: $check-blue;
}
.home--background-dog {
  background-color: $dog-red;
}

.home--background-brainflix {
  background-color: $brainflix;
}

.home--background-dragcity {
  background-color: $dragcity;
}

.home--background-skills {
  background-color: $background-black;
}

.blank {
  height: 300px;
  width: 100%;
}
