@import "../../styles/globals.scss";

.project {
  width: 100%;
  margin-top: to-rem(60px);
  margin-bottom: to-rem(20px);

  &--hide {
    display: none;
  }

  &:not(:first-child) {
    @include breakpoint(laptop) {
      margin-top: to-rem(150px);
    }
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__project-number {
    @include heading-1-mobile;
    opacity: 0;
    position: relative;

    @include breakpoint(tabletS) {
      @include heading-2;
    }

    @include breakpoint(laptop) {
      @include heading-3;
    }

    &--animate {
      animation: 1.5s ease-in-out mountadexSlideInUp forwards;
    }
  }

  &__heading {
    @include heading-5-mobile;
    margin-top: to-rem(-5px);
    opacity: 0;
    position: relative;
    text-transform: capitalize;

    @include breakpoint(tabletS) {
      @include heading-4-mobile;
    }

    @include breakpoint(laptop) {
      @include heading-8;
    }

    &--animate {
      animation: 1.5s ease-in-out 0.5s mountadexSlideInUp forwards;
    }
  }

  &__image-container {
    margin: 0 to-rem(-32px);
    opacity: 0;
    position: relative;

    @include breakpoint(tabletS) {
      margin: 0 to-rem(-64px);
    }

    @include breakpoint(laptop) {
      margin: 0 to-rem(-120px);
    }

    @include breakpoint(desktopS) {
      margin: 0 to-rem(-160px);
    }

    @include breakpoint(desktopL) {
      margin: 0 to-rem(-200px);
    }

    &--animate {
      animation: 1s ease-in-out 0.75s mountadexSlideInUpOne forwards;
    }
  }

  &__image {
    width: 100%;
  }
  &__link-container {
    width: 100%;
    margin-top: to-rem(10px);
    display: flex;
    text-transform: uppercase;
    color: $mountadex-hoverblue;

    @include breakpoint(desktopS) {
      margin-top: -30px;
    }

    &--single {
      justify-content: center;
      text-align: center;
    }
  }

  &__single-link-container {
    width: 50%;
    @include breakpoint(desktopS) {
      width: 50%;
    }

    &--site {
      margin-right: to-rem(15px);
      text-align: right;

      @include breakpoint(laptop) {
        margin-right: to-rem(20px);
      }
    }

    &--git {
      margin-left: to-rem(15px);

      @include breakpoint(laptop) {
        margin-left: to-rem(20px);
      }
    }
  }

  &__underline-container {
    display: inline-block;
    position: relative;
    opacity: 0;

    &--animate-one {
      animation: 0.5s ease-in-out mountadexSlideInUpSmall forwards;
    }

    &--animate-two {
      animation: 0.5s ease-in-out 0.5s mountadexSlideInUpSmall forwards;
    }

    &:hover {
      .project__link {
        top: -5px;
      }
    }
  }

  &__link {
    text-decoration: none;
    padding: 0 to-rem(2px) to-rem(5px) to-rem(2px);
    transition: 0.4s ease-in-out;
    position: relative;
    top: 0;

    @include breakpoint(tabletS) {
      @include body-large;
    }

    @include breakpoint(desktopL) {
      @include body-medium;
    }
  }

  &__link-underline {
    height: 1px;
    width: 100%;
    background-color: $mountadex-paletteblue;

    &--site {
      margin-right: to-rem(20px);

      @include breakpoint(desktopS) {
        text-align: right;
        margin-right: to-rem(0px);
      }
    }

    &--git {
      margin-left: to-rem(20px);

      @include breakpoint(desktopS) {
        text-align: left;
        margin-left: to-rem(0px);
      }
    }
  }

  &__description-container {
    @include breakpoint(desktopS) {
      margin-top: to-rem(40px);
    }
  }

  &__description {
    margin-top: to-rem(35px);
    @include body-small-mobile;
    text-align: center;
    position: relative;
    opacity: 0;
    @include breakpoint(tabletS) {
      @include body-large;
    }

    @include breakpoint(laptop) {
      @include body-regular;
    }

    @include breakpoint(tabletL) {
      margin-top: to-rem(50px);
    }

    @include breakpoint(desktopS) {
      @include body-regular;
      margin: to-rem(50px) to-rem(150px) 0 to-rem(150px);
      line-height: to-rem(28px);
    }

    &--animate-one {
      animation: 1.5s ease-in-out mountadexSlideInUp forwards;
    }
  }

  &__container-margin {
    margin: to-rem(40px) to-rem(-32px);
    width: calc(100% + 64px);
    position: relative;
    height: to-rem(154px);

    @include breakpoint(tabletS) {
      margin: to-rem(60px) to-rem(-64px);
      width: calc(100% + 128px);
      height: to-rem(204px);
    }

    @include breakpoint(laptop) {
      margin: to-rem(80px) to-rem(-120px);
      width: calc(100% + 240px);
      height: to-rem(204px);
    }

    @include breakpoint(tabletL) {
      margin: to-rem(80px) to-rem(-120px);
      height: to-rem(264px);
    }

    @include breakpoint(desktopS) {
      margin: to-rem(110px) to-rem(-160px);
      width: calc(100% + 320px);
      height: to-rem(294px);
    }

    @include breakpoint(desktopL) {
      margin: to-rem(110px) to-rem(-200px);
      width: calc(100% + 400px);
    }
  }

  &__tech-animate-container {
    position: absolute;
    overflow: hidden;
    z-index: 3;
    left: 0;
    top: 0;
    right: 0;
    bottom: 100%;
    background-color: #000;

    &--animate {
      animation: 1s ease-in-out forwards techContainerDown;
    }
  }

  &__techstack-container {
    padding: to-rem(40px) 0;
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto;

    @include breakpoint(mobileM) {
      max-width: to-rem(375px);
    }

    @include breakpoint(tabletS) {
      padding: to-rem(60px) 0;
      max-width: to-rem(405px);
    }

    @include breakpoint(tabletL) {
      padding: to-rem(80px) 0;
      max-width: to-rem(600px);
    }

    @include breakpoint(desktopS) {
      padding: to-rem(80px) 0;
      max-width: to-rem(750px);
    }
  }

  &__tech-icon {
    @include animation;
    width: to-rem(70px);
    height: to-rem(70px);
    fill: #fff;
    display: flex;
    align-items: center;

    @include breakpoint(tabletS) {
      width: to-rem(80px);
      height: to-rem(80px);
    }

    @include breakpoint(tabletL) {
      width: to-rem(100px);
      height: to-rem(100px);
    }

    @include breakpoint(desktopS) {
      width: to-rem(130px);
      height: to-rem(130px);
    }

    &--animate-one {
      animation: 0.5s ease-in-out mountadexSlideInUpSmall forwards;
    }

    &--animate-two {
      animation: 0.5s ease-in-out 0.25s mountadexSlideInUpSmall forwards;
    }

    &--animate-three {
      animation: 0.5s ease-in-out 0.5s mountadexSlideInUpSmall forwards;
    }

    &--animate-four {
      animation: 0.5s ease-in-out 0.75s mountadexSlideInUpSmall forwards;
    }

    &:hover path,
    &:hover circle {
      fill: $mountadex-bluemountain;
    }

    & path {
      fill: $white;
    }
  }

  &__tech-hover {
    &--omdb {
      &:hover path,
      &:hover circle {
        fill: $omdb-pink;
      }
    }
    &--mountadex {
      &:hover path,
      &:hover circle {
        fill: $mountadex-bluemountain;
      }
    }

    &--event {
      &:hover path,
      &:hover circle {
        fill: $eventfriends-blue;
      }
    }

    &--check {
      &:hover path,
      &:hover circle {
        fill: $check-blue;
      }
    }

    &--dog {
      &:hover path,
      &:hover circle {
        fill: $dog-red;
      }
    }

    &--brainflix {
      &:hover path,
      &:hover circle {
        fill: $brainflix;
      }
    }

    &--dragcity {
      &:hover path,
      &:hover circle {
        fill: $dragcity;
      }
    }
  }

  &__gallery-container {
    width: 100%;
    text-align: center;
  }

  &__gallery-title {
    @include heading-6-mobile;
    @include animation;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: to-rem(10px);
    letter-spacing: to-rem(1px);

    @include breakpoint(tabletS) {
      @include heading-5-mobile;
    }

    @include breakpoint(tabletL) {
      @include heading-7;
    }

    @include breakpoint(desktopS) {
      @include heading-8;
    }

    &--animate {
      animation: 1.5s ease-in-out mountadexSlideInUp forwards;
    }
  }

  &__gallery-underline {
    width: 0;
    height: 1px;
    background-color: $mountadex-paletteblue-opacity;
    margin-bottom: to-rem(40px);

    @include breakpoint(tabletS) {
      margin-bottom: to-rem(50px);
    }

    @include breakpoint(tabletL) {
      margin-bottom: to-rem(70px);
    }

    @include breakpoint(desktopS) {
      margin-bottom: to-rem(90px);
    }

    &--animate {
      animation: 1s ease-in-out 1s underlineAnimate forwards;
    }
  }

  &__figures-container {
    @include breakpoint(desktopS) {
      display: flex;
    }
    @include breakpoint(desktopL) {
      max-width: to-rem(1040px);
      margin: 0 auto;
    }
  }

  &__figures-left {
    @include breakpoint(desktopS) {
      margin-right: to-rem(20px);
      margin-top: to-rem(100px);
      width: 50%;
      flex-shrink: 0;
      width: calc(50% - 20px);
      text-align: right;
    }

    &--event {
      @include breakpoint(desktopS) {
        margin: 0 to-rem(10px) to-rem(200px) 0;
        text-align: right;
        width: calc(50% - 10px);
      }
    }
  }

  &__figures-right {
    @include breakpoint(desktopS) {
      width: 50%;
      flex-shrink: 0;
      width: calc(50% - 20px);
      text-align: left;
    }

    &--event {
      @include breakpoint(desktopS) {
        margin: 0 0 0 to-rem(10px);
        text-align: left;
        width: calc(50% - 10px);
      }
    }
  }

  &__figure {
    @include animation;
    width: 100%;
    text-align: right;
    margin-bottom: to-rem(30px);

    @include breakpoint(tabletS) {
      margin-bottom: to-rem(50px);
    }

    @include breakpoint(tabletL) {
      margin-bottom: to-rem(70px);
    }

    @include breakpoint(desktopS) {
      margin-bottom: to-rem(60px);
      font-size: 0;
      position: unset;
      opacity: 1;
      display: inline-block;
      width: unset;
    }

    @include breakpoint(desktopL) {
      margin-bottom: to-rem(70px);
    }

    &--one {
      @include breakpoint(desktopS) {
        margin-left: to-rem(-100px);
        margin-right: to-rem(140px);
      }
    }

    &--two {
      @include breakpoint(desktopS) {
        margin-left: to-rem(10px);
      }
    }
    &--three {
      @include breakpoint(desktopS) {
        margin-left: to-rem(-90px);
      }
    }

    &--four {
      @include breakpoint(tabletS) {
        margin-top: to-rem(100px);
      }
      @include breakpoint(desktopS) {
        margin-left: to-rem(220px);
        margin-top: 0;
        margin-bottom: to-rem(15px);
      }
      @include breakpoint(desktopL) {
        margin-left: to-rem(240px);
      }
    }

    &--animate {
      animation: 2s ease-in-out mountadexSlideInUp forwards;
    }

    &--event-one {
      width: 65%;
      margin: 0 auto;
      margin-bottom: to-rem(30px);

      @include breakpoint(tabletS) {
        width: 50%;
        margin-bottom: to-rem(50px);
      }

      @include breakpoint(laptop) {
        width: 30%;
        margin: 0;
        display: inline-block;
        margin-right: to-rem(40px);
      }

      @include breakpoint(desktopS) {
        margin: 0;
        width: unset;
        margin-right: to-rem(10px);
      }
    }

    &--event-two {
      width: 65%;
      margin: 0 auto;
      margin-bottom: to-rem(30px);

      @include breakpoint(tabletS) {
        width: 50%;
        margin-bottom: to-rem(50px);
      }

      @include breakpoint(laptop) {
        width: 30%;
        margin: 0;
        display: inline-block;
        margin-left: to-rem(40px);
      }

      @include breakpoint(desktopS) {
        margin: 0;
        width: unset;
        margin-left: to-rem(10px);
        transform: translateY(90px);
      }
    }
    &--event-three {
      width: 65%;
      margin: 0 auto;
      margin-bottom: to-rem(30px);

      @include breakpoint(tabletS) {
        width: 50%;
        margin-bottom: to-rem(50px);
      }

      @include breakpoint(laptop) {
        width: 30%;
        margin: 0;
        display: inline-block;
        margin-right: to-rem(40px);
      }

      @include breakpoint(desktopS) {
        margin: 0;
        width: unset;
        margin-right: to-rem(10px);
      }
    }

    &--event-four {
      width: 65%;
      margin: 0 auto;
      margin-bottom: to-rem(30px);

      @include breakpoint(tabletS) {
        width: 50%;
        margin-bottom: to-rem(50px);
      }

      @include breakpoint(laptop) {
        width: 30%;
        margin: 0;
        display: inline-block;
        margin-left: to-rem(40px);
        margin-top: to-rem(60px);
      }

      @include breakpoint(desktopS) {
        margin: 0;
        width: unset;
        margin-left: to-rem(10px);
        transform: translateY(90px);
        margin-bottom: to-rem(80px);
      }
    }

    &--check-one {
      @include breakpoint(desktopS) {
        margin-top: to-rem(60px);
        margin-left: to-rem(-100px);
        margin-right: to-rem(16px);
      }
    }
    &--check-two {
      @include breakpoint(desktopS) {
        margin-left: to-rem(-100px);
        margin-right: to-rem(16px);
      }
    }
    &--check-three {
      @include breakpoint(desktopS) {
        margin: 0;
        margin-right: to-rem(-100px);
        margin-left: to-rem(16px);
      }
    }
    &--check-four {
      @include breakpoint(desktopS) {
        margin: to-rem(50px) to-rem(-100px) 0 to-rem(16px);
      }
    }
  }

  &__gallery-image-container {
    width: 100%;
    margin-bottom: to-rem(5px);
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px rgba(135, 131, 135, 1);

    @include breakpoint(desktopS) {
      margin-bottom: to-rem(10px);
      position: relative;
      width: unset;
      transition: 0.5s ease-in-out;
      box-shadow: none;
    }

    &--shadow {
      box-shadow: 0px 0px 30px 0px rgba(135, 131, 135, 1);
    }

    &--one {
      @include breakpoint(desktopS) {
        width: to-rem(376px);
        height: to-rem(501px);
      }
      @include breakpoint(desktopL) {
        width: to-rem(409px);
        height: to-rem(545px);
      }
    }

    &--two {
      @include breakpoint(desktopS) {
        width: to-rem(630px);
        height: to-rem(398px);
      }
      @include breakpoint(desktopL) {
        width: to-rem(671px);
        height: to-rem(424px);
      }
    }

    &--three {
      @include breakpoint(desktopS) {
        width: to-rem(630px);
        height: to-rem(398px);
      }
      @include breakpoint(desktopL) {
        width: to-rem(671px);
        height: to-rem(424px);
      }
    }

    &--four {
      @include breakpoint(desktopS) {
        width: to-rem(225px);
        height: to-rem(487px);
      }

      @include breakpoint(desktopL) {
        width: to-rem(245px);
        height: to-rem(530px);
      }
    }
  }

  &__gallery-animate {
    font-size: 0;
    // box-shadow:
    @include breakpoint(desktopS) {
      overflow: hidden;
      position: absolute;
    }
    &--one {
      @include breakpoint(desktopS) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 100%;
      }

      &--animate {
        animation: 1.5s forwards ease-in-out topDown;
      }
    }
    &--two {
      @include breakpoint(desktopS) {
        top: 0;
        left: 0;
        right: 100%;
        bottom: 100%;
      }

      &--animate {
        animation: 1.5s forwards ease-in-out leftToRight;
      }
    }
    &--three {
      @include breakpoint(desktopS) {
        top: 0;
        left: 0;
        right: 100%;
        bottom: 100%;

        &--animate {
          animation: 1.5s forwards ease-in-out leftToRight;
        }
      }
    }
    &--four {
      @include breakpoint(desktopS) {
        top: 0;
        left: 0;
        right: 0;
        bottom: 100%;
      }
      &--animate {
        animation: 1.5s forwards ease-in-out topDown;
      }
    }
  }

  &__gallery-image {
    width: 100%;
    transition: 0.5s ease-in;

    &:hover {
      transform: scale(1.1);
    }

    &--one {
      @include breakpoint(desktopS) {
        width: to-rem(376px);
        height: to-rem(501px);
      }
      @include breakpoint(desktopL) {
        width: to-rem(409px);
        height: to-rem(545px);
      }
    }

    &--two {
      @include breakpoint(desktopS) {
        width: to-rem(630px);
        height: to-rem(398px);
      }
      @include breakpoint(desktopL) {
        width: to-rem(671px);
        height: to-rem(424px);
      }
    }

    &--three {
      @include breakpoint(desktopS) {
        width: to-rem(630px);
        height: to-rem(398px);
      }
      @include breakpoint(desktopL) {
        width: to-rem(671px);
        height: to-rem(424px);
      }
    }

    &--four {
      @include breakpoint(desktopS) {
        width: to-rem(225px);
        height: to-rem(487px);
      }

      @include breakpoint(desktopL) {
        width: to-rem(245px);
        height: to-rem(530px);
      }
    }

    &--event-friends {
      @include breakpoint(desktopS) {
        width: to-rem(225px);
        height: to-rem(487px);
      }

      @include breakpoint(desktopL) {
        margin-left: 0;
        width: to-rem(245px);
        height: to-rem(530px);
      }
    }

    &--check {
      @include breakpoint(desktopS) {
        width: to-rem(560px);
        height: to-rem(317px);
      }
    }
  }

  &__caption {
    @include body-small-mobile;
    text-transform: uppercase;
    display: inline-block;
    text-align: right;
    @include breakpoint(tabletS) {
      @include body-medium-mobile;
    }

    @include breakpoint(laptop) {
      @include body-regular;
    }

    @include breakpoint(tabletL) {
      @include body-large;
    }

    @include breakpoint(desktopS) {
      @include body-medium;
      line-height: 1.1;
      width: 90%;
    }
    @include breakpoint(desktopL) {
      margin-top: to-rem(3px);
    }

    &--four {
      @include breakpoint(desktopS) {
        width: to-rem(225px);
      }

      @include breakpoint(desktopL) {
        width: to-rem(245px);
      }
    }
  }
}

@keyframes aboutSlideInLeft {
  0% {
    opacity: 0;
    left: -40px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes mountadexSlideInUp {
  0% {
    opacity: 0;
    top: 60px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes mountadexSlideInUpOne {
  0% {
    opacity: 0;
    top: 100px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes mountadexSlideInUpSmall {
  0% {
    opacity: 0;
    top: 30px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes projectsFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes techContainerDown {
  0% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
  }
  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes underlineAnimate {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes topDown {
  0% {
    top: 0;
    left: 0;
    right: 100%;
    bottom: 100%;
    opacity: 0;
  }
  25% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 99%;
  }
  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
}

@keyframes leftToRight {
  0% {
    top: 0;
    left: 0;
    right: 100%;
    bottom: 100%;
    opacity: 0;
  }
  25% {
    top: 0;
    left: 0;
    right: 99%;
    bottom: 0;
  }
  100% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
}

@keyframes underlineAnimate {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
